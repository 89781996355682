/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import {ValidEmail} from '@/services/validation.service'
import './reset-password.page.pug'
import { CustomerState } from '@/store/customer/types'
import * as messageModal from "@/store/message-modal";


const namespace: string = 'customer';


export default class ResetPasswordPage extends Vue {
	email: string = '';
	typeInput: string = 'email';
	isValid: string = '';
	errorName: string = '';

	@State('customer') customer: CustomerState | undefined;
	@Action('resetPassword', {namespace}) resetPassword: any;
	@Getter('getRequestStatus', { namespace }) getRequestStatus: any;
	@Getter('getMessages', {namespace}) getMessages: any;
	@Mutation('setClearMessages', {namespace}) setClearMessages: any;

	onSubmit(): void {
		this.setClearMessages();
		this.isValid = 'is-valid'
		if (ValidEmail(this.email)) {
			this.getRequestStatus ? this.isValid = 'is-valid': '';
			this.resetPassword({
				email: this.email
			}).then(function () {
                messageModal.Show("Сообщение", "Сообщение с инструкцией по восстановлению пароля отправлено вам на почту", "Перейти на главную", "/");
            });
			// this.email = '';
			// this.$router.push({name: 'login'}).then(r => r);
		} else {
			!this.getRequestStatus ? this.isValid = 'is-invalid' : '';
			console.log('incorrect email')
		}
	}

	mounted()
	{
        messageModal.Init();
	}
}
